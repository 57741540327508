<template>
  <div id="home">
    <ul class="sns">
      <li><a href="https://github.com/Usag1"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-github text-git" viewBox="0 0 16 16">
          <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
          </svg></a>
      </li>
      <li><a href="https://www.instagram.com/devgirl_krn/"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
          </svg></a>
      </li>
      <li><a href="https://twitter.com/devgirlk"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
          </svg></a>
      </li>
    </ul>
    <div class="container">
      <div class="home-center">
        <h1>Karin Ikenaga</h1>
        <p>Web designer<br>&<br> Frontend developer</p>
        <router-link :to="{ name:'Contact' }" class="button" >
        Contact me
        </router-link>
      </div>
      <div class="home-img">
        <img class="my-pic" src="../assets/home-pic1.png" alt="my-pic">
        <span class="frame1"></span>
        <span class="frame2"></span>
        <span class="frame3"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>

<style scoped>
#home {
  min-height: 80vh;
  padding: 3%;
  display: grid;
  grid-template-columns: 30px 1fr;
}
.container {
  position: relative;
}
.home-center {
  position: absolute;
  top: 42%;
  left: 10%;
}
#home .button {
  border-radius: 4px;
  padding: 6px 12px;
  text-decoration: none;
  background: #00838F;
  color: #212121;
  cursor: pointer;
  font-size: 1.5rem;
  transition: all .2s;  
}
#home .button:hover {
  box-shadow: 0 0 8px 2px #00838F; 
}
#home h1 {
  font-size: 2.7rem;
}
#home p {
  font-size: 1.5rem;
  line-height: 30px;
}
.home-img {
  position: absolute;
  top: 12%;
  right: 10%;
}
.my-pic {
  position: relative;
  height: 35rem;
  border-radius: 80%;
  z-index: 3;
}
.frame1 {
  position: absolute;
  top: -9%;
  right: 21%;
  transform: rotate(-45deg);
  width: 15rem;
  height: 42rem;
  border: solid 2px #00838f;
  border-radius: 50%; 
  box-shadow: 1px 0 5px 1px #00838f;
}
.frame2 {
  position: absolute;
  top: -9%;
  right: 21%;
  transform: rotate(45deg);
  width: 15rem;
  height: 42rem;
  border: solid 2px #00838F;
  border-radius: 50%;
  box-shadow: 1px 0 5px 1px #00838f;
}
.frame3 {
  position: absolute;
  top: -9%;
  right: 21%;
  transform: rotate(90deg);
  width: 15rem;
  height: 42rem;
  border: solid 2px #ffffff;
  border-radius: 50%;
  box-shadow: 1px 0 5px 1px;
}
#home ul {
  list-style: none;
  margin: auto 0;
  padding: 0;
}
.bi {
  margin-bottom: 96px;
  color: white;
  transition: all .4s;
}
.bi:hover {
  color: #00838F;
  transform: scale(1.3);
}
 
@media screen and (max-width: 1200px), 
       screen and (max-height: 850px){
  .container {
    padding-top: 30%;
  }
  .my-pic {
    height: 24rem;
    left: 3%;
  }
  #home h1 {
  font-size: 1.5rem;
  font-weight: bold;
  }
  #home p {
  font-size: 1.2rem;
  line-height: 25px;
  }
  .frame1 {
    width: 9rem;
    height: 27rem;
  }
  .frame2 {
    width: 9rem;
    height: 27rem;
  }
  .frame3 {
    width: 9rem;
    height: 27rem;
  }
}
/* Mobile & Tablet */
@media (max-width: 768px) {
  #home {
    display: flex;
    flex-flow: column;
  }
  #home .button {
    border-radius: 4px;
    padding: 6px 12px;
    text-decoration: none;
    background: #00838F;
    color: #212121;
    cursor: pointer;
    font-size: 1rem;
    transition: all .2s;  
  }
  .container {
    height: 100%;
    margin: 0;
    padding: 20% 0;
  }
  .home-center {
    position: relative;
    left: 0;    
  }
  #home h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #home p {
    font-size: 1.2rem;
    line-height: 25px;
  }
  .home-img {
    display: none;
  }
  #home ul {
    margin: 0;
    display: flex;
    justify-content: center;
    padding-top: 16%;
  }
  .bi {
    margin: 0 .6em;
  }
}
</style>
